import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { CircularProgress } from "@mui/material";
const AccountTest = lazy(() => import("../pages/account_test"));
const NotFound = lazy(() => import("../pages/not-found"));
const routeList = [
  {
    key: "account_test",
    name: "推特账号测试",
    path: "/account_test",
    component: AccountTest,
  },
];
function homeRoutes() {
  return (
    <div>
      <Routes>
        <Route
          index
          element={
            <Suspense fallback={<CircularProgress />}>
              <AccountTest />
            </Suspense>
          }
        ></Route>
        {routeList.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={
                <Suspense fallback={<CircularProgress />}>
                  <item.component />
                </Suspense>
              }
            ></Route>
          );
        })}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}
export default homeRoutes;
