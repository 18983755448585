import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.less";

import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import Routes from "../../route/homeRoutes";

const Activity = () => {
  const [value, setValue] = useState(0);
  const ref = React.useRef(null);
  const navigate = useNavigate();
  // useEffect(() => {
  //   ref.current.ownerDocument.body.scrollTop = 0;
  // }, [value]);

  return (
    <Box ref={ref}>
      <Routes />
    </Box>
  );
};
export default Activity;
