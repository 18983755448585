import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Activity from "./pages/activity";
import NotFound from "./pages/not-found";
import { CircularProgress } from "@mui/material";
function App() {
  return (
    <Routes>
      <Route
        index
        element={
          <Suspense fallback={<CircularProgress />}>
            <Activity />
          </Suspense>
        }
      />
      <Route
        path="/activity/*"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Activity />
          </Suspense>
        }
      />
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
}

export default App;
